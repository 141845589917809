import React,{ useState, useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, addDoc,updateDoc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db, upload, storage } from "./firebase"
import { ref, getDownloadURL, listAll } from "firebase/storage";
import ReactLoading from 'react-loading';

const BuildingDocuments = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const buildingId = location.pathname.split("/")[1]
    const [BuildingDocuments, setBuildingDocuments] = useState("")
    const [loadingFlag, setLoadingFlag] = useState(false)

    useEffect(()=>{
        getBuildingDocuments()

        async function getBuildingDocuments(){
          setLoadingFlag(true)
          let result = []
          const documentsListRef = ref(storage, `/${buildingId}/documents`);
          listAll(documentsListRef)
            .then((res) => {
              res.items.forEach((itemRef) => {
                console.log(itemRef._location.path, "itemRef")
                const storageRef = ref(storage, itemRef._location.path);
                    getDownloadURL(storageRef).then(async (url) => {
                        result.push({name: itemRef.name, url: url})
                    })
              });
            }).catch((error) => {
              // Uh-oh, an error occurred!
            });

            await delay(3)
            setBuildingDocuments(result)
            setLoadingFlag(false)
        }  
    }, [])  

    function delay(n){
        return new Promise(function(resolve){
            setTimeout(resolve,n*1000);
        });
      }

  return(
   <>
   {loadingFlag  ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
    <div>
      <div style={{textAlign: "left", padding: "10px 5%"}}>
        <h3 style={{borderLeft: "medium solid #299fd6", marginBottom: 25}}>　建物資料</h3> 
        
        {BuildingDocuments.length>0 ?
        <>
          {BuildingDocuments.map((doc, i)=>{
            return(
              <div key={i} style={{marginTop:10}}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</a>
              </div>
            )
          })}
        </>:
          <p>提出頂いた資料はありません。</p> 
        }

    <div style={{display: "flex", justifyContent: "space-around"}}>
      <button style={{marginTop:50, height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer"}} onClick={()=>{navigate(`/myreport`)}}>戻る</button>
    </div>
 
      </div> 
    </div>}
   </>   
  )  
}

export default BuildingDocuments;