import React,{ useState, useEffect } from "react"
import responsiveStyles from "./Responsive.module.css"
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';


const Selfcheck = () => {
  const [pageFlag, setPageFlag] = useState(0)  
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false)  
  const navigate = useNavigate()

  // 追記: q37は削除

  const [state, setState] = React.useState({
    q1: "", q2: "", q3: "", q4: "", q5: "",q6: "",q7: "",q8: "",q9: "",q10: "",q11: "",q12: "",
    q13: "",q14: "",q15: "",q16: "",q17: "",q18: "",q19: "", q20: "", q21: "", q22: "", q23: "", q24: "", q25: "", q26: "", q27: "", q28: "", q29: "",
    q30: "", q31: "", q32: "", q33: "", q34: "", q35: "", q36: "", q38: ""
  });

  const handleChange = (event) => {
    console.log(event.target.value)
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const { q1,q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q38 } = state;
  const hasDoneInPage1 = [q1, q2, q3].filter((v) => v).length === 3;
  const hasDoneInPage2 = [q4, q5, q6, q7].filter((v) => v).length === 4;
  const hasDoneInPage3 = [q8, q9, q10, q11].filter((v) => v).length === 4;
  const hasDoneInPage4 = [q12, q13, q14, q15].filter((v) => v).length === 4;
  const hasDoneInPage5 = [q16].filter((v) => v).length === 1;
  const hasDoneInPage6 = [q17, q18, q19, q20, q21, q22].filter((v) => v).length === 6;
  const hasDoneInPage7 = [q23, q24, q25, q26].filter((v) => v).length === 4;
  const hasDoneInPage8 = [q27, q28, q29, q30, q31, q32, q33].filter((v) => v).length === 7;
  const hasDoneInPage9 = [q34, q35, q36].filter((v) => v).length === 3;
  const hasDoneInPage10 = [q38].filter((v) => v).length === 1;


  // 検査結果
  function outputResultComment(){
  let resultComment = "一度ホームインスペクターに電話で相談してみてはいかがでしょうか？ホームインスペクターは中立的な立場でお悩みを聞いてもらえると思います。"
  // 全て「ない」の場合
  const isErrorNon = [q1,q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q38].every(v=>v==="なし")
  // 「ある」があった場合
  const hascheckedExist = [q1,q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q38].some(v=>v==="あり")
  // 36,37に「ある」があった場合
  const hascheckedExistIn36Or37 = [q36].some(v=>v==="あり")
  // 28,29,38に「ある」があった場合
  const hascheckedExistIn28Or29Or38 = [q28, q29, q38].some(v=>v==="あり")
  // 4,8,9,10,11,15に「ある」が２つ以上ある場合
  const hascheckedExistOver2 = [q4, q8, q9, q10, q11, q15].filter(v=>v==="あり").length>=2
  // 3,7,26,30に「ある」があった場合
  const hascheckedExistIn3Or7Or26Or30 = [q3, q7, q26, q30].some(v=>v==="あり")
  // 「わからない」に一つでも◯がある場合
  const hascheckedUnknown =  [q1,q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q38].some(v=>v==="わからない")
  // 32に「ある」があった場合
  const hascheckedExistIn32 = [q32].some(v=>v==="あり")
  // 2,22に「ある」があった場合
  const hascheckedExistIn2Or32 = [q2, q22].some(v=>v==="あり")

  if(hascheckedExistIn2Or32){
    resultComment = "早急に詳細調査（ホームインスペクション）を実施する事をお勧めします。筒状の土のかたまりは蟻道というシロアリの通り道の可能性があります。その他にも建物に不具合がある可能性もあります。" 
  } else if(hascheckedExistIn32){
    resultComment = "早急に詳細調査（ホームインスペクション）を実施する事をお勧めします。床の傾きは地盤の不動沈下や建物の構造的な問題の可能性があります。原因を知る事で対策も立てられます。その他にも建物に不具合がある可能性もあります。" 
  } else if(hascheckedExistIn3Or7Or26Or30){
    resultComment = "建物調査の専門家によるホームインスペクションをお勧めします。雨漏りや結露は建物に致命的な影響を与えます。水濡れの原因究明により対策が立てられ、結果的に住まいを長持ちさせる事ができます。" 
  } else if(hascheckedExistIn3Or7Or26Or30){
    resultComment = "建物調査の専門家によるホームインスペクションをお勧めします。雨漏りや結露は建物に致命的な影響を与えます。水濡れの原因究明により対策が立てられ、結果的に住まいを長持ちさせる事ができます。" 
  } else if(hascheckedUnknown){
    resultComment = "建物調査の専門家によるホームインスペクションをお勧めします。ホームインスペクションは定期的に実施する事で、結果的に住まいを長持ちさせる事ができます。" 
  } else if(hascheckedExistOver2){
    resultComment = "塗装が劣化していると思われます。塗装は１０年から１５年で塗り替えが必要になります。定期的にメンテナンスする事で住まいの寿命に大きな差が出てきます。早めに対応される事をお勧めします。" 
  } else if(hascheckedExistIn28Or29Or38){
    resultComment = "DIYによる住まいのメンテナンスに挑戦してみてはいかがですか？最近はホームセンターで手軽にDIYができる材料が揃います。DIYによって自分好みの内装に仕上げる楽しみはワクワクします。" 
  } else if(hascheckedExistIn36Or37){
    resultComment = "加熱機器は１０年から１５年が交換時期です。最近はホームセンターや家電量販店でも比較的安価で加熱機器の交換を実施しています。そろそろ準備をしておいてはいかがですか？" 
  } else if(hascheckedExist){
    resultComment = "一度ホームインスペクターに電話で相談してみてはいかがでしょうか？ホームインスペクターは中立的な立場でお悩みを聞いてもらえると思います。"  
  } else if(isErrorNon){
    resultComment = "現時点でお住まいは良好な状態だと思われます。ただしお住まいは定期的なチェックが必要です。又屋根・外壁・設備などは定期的にメンテナンスや補修が必要になります。計画的にメンテナンス費用を確保することをお勧めします。" 
  }

  console.log(resultComment, "resultComment")
  return resultComment
}

  return (
    <div className={pageFlag===11 || pageFlag===0 ? "": responsiveStyles.frame }>
      {pageFlag === 0 &&  
      <div style={{backgroundColor: "#EEEEEE", height: "100vh",marginTop: 10, paddingTop: 60}}>
     
      <div style={{ borderRadius: 10, backgroundColor: "#fff", margin: "0px 30px", padding: "10px 0px"}}>
          
      <div style={{textAlign: "center"}}>
         <p style={{fontWeight: "bold", fontSize: "36px", color: "#299fd6"}}>セルフ診断</p> 
         <p>ご自身で簡単に物件診断を<br/>行うことができます。</p>
         <p>所要時間 : 約3分</p>
      </div>  

    <div style={{marginBottom: 20, marginTop: 50}}>  
       <button style={{width: "250px", height: 45, backgroundColor: "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: "1px solid orange", borderRadius: 1 }} onClick={()=>{
           setPageFlag(1)
        }}
        >診断スタート ＞</button>
    </div> 
      
    </div>
    </div>}

      {pageFlag === 1 &&  
      <>
      <Box>       
      <FormControl style={{ display: 'flex' }}  >
        <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
           <div style={{width: "50%",fontSize: 18, backgroundColor: "#1c4fe5", padding: 2, borderRadius: 6, color: "#fff", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
           <div style={{width: "50%",fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid orange", color: "orange", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(1)}} style={{fontSize: 35, color: "#1c4fe5", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1){
                    setDisplayErrorMessage(false)     
                    setPageFlag(2)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q1: 基礎にひび割れはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox  checked={q1==="なし"} onChange={handleChange} name="q1" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q1==="あり"} onChange={handleChange} name="q1" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q1==="わからない"} onChange={handleChange} name="q1" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q2: 基礎に筒状の土のかたまりがついていませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q2==="なし"} onChange={handleChange} name="q2" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q2==="あり"} onChange={handleChange} name="q2" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q2==="わからない"} onChange={handleChange} name="q2" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q3: 雨ではないのに基礎に濡れたところや水跡はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q3==="なし"} onChange={handleChange} name="q3" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q3==="あり"} onChange={handleChange} name="q3" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q3==="わからない"} onChange={handleChange} name="q3" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}

    <div style={{marginBottom: 20, marginTop: 50}}>  
       <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
           if(hasDoneInPage1){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
           }else {
             setDisplayErrorMessage(true)  
           }
        }}
        >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 2 &&
    <>  
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <div style={{width: "50%",fontSize: 18, backgroundColor: "#1c4fe5", padding: 2, borderRadius: 6, color: "#fff", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
          <div style={{width: "50%",fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid orange", color: "orange", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(1)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1){
                    setDisplayErrorMessage(false)     
                    setPageFlag(2)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#1c4fe5", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1 && hasDoneInPage2){
                    setDisplayErrorMessage(false)     
                    setPageFlag(3)
                }else {
                 setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q4: 屋根に苔や変色はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q4==="なし"} onChange={handleChange} name="q4" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q4==="あり"} onChange={handleChange} name="q4" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q4==="わからない"} onChange={handleChange} name="q4" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q5: 屋根に太陽光発電、太陽光温水器は設置されていますか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q5==="なし"} onChange={handleChange} name="q5" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q5==="あり"} onChange={handleChange} name="q5" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q5==="わからない"} onChange={handleChange} name="q5" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q6: 雨樋が外れていたり、歪んでいる部分はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q6==="なし"} onChange={handleChange} name="q6" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q6==="あり"} onChange={handleChange} name="q6" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q6==="わからない"} onChange={handleChange} name="q6" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q7: 軒裏に雨染みがありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q7==="なし"} onChange={handleChange} name="q7" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q7==="あり"} onChange={handleChange} name="q7" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q7==="わからない"} onChange={handleChange} name="q7" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage2){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
             setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 3 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
         <div style={{width: "50%",fontSize: 18, backgroundColor: "#1c4fe5", padding: 2, borderRadius: 6, color: "#fff", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
         <div style={{width: "50%",fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid orange", color: "orange", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(1)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1){
                    setDisplayErrorMessage(false)     
                    setPageFlag(2)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1 && hasDoneInPage2){
                    setDisplayErrorMessage(false)     
                    setPageFlag(3)
                }else {
                 setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color: "#1c4fe5", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1 && hasDoneInPage2 && hasDoneInPage3){
                    setDisplayErrorMessage(false)     
                    setPageFlag(4)
                }else {
                 setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q8: 外壁にひび割れはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q8==="なし"} onChange={handleChange} name="q8" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q8==="あり"} onChange={handleChange} name="q8" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q8==="わからない"} onChange={handleChange} name="q8" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q9: 外壁をさわると手に白い粉がつきませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q9==="なし"} onChange={handleChange} name="q9" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q9==="あり"} onChange={handleChange} name="q9" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q9==="わからない"} onChange={handleChange} name="q9" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q10: 外壁に苔や変色はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q10==="なし"} onChange={handleChange} name="q10" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q10==="あり"} onChange={handleChange} name="q10" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q10==="わからない"} onChange={handleChange} name="q10" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q11: 外壁のつなぎ目のシーリング材（ゴム状の防水材）にひび割れや亀裂はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q11==="なし"} onChange={handleChange} name="q11" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q11==="あり"} onChange={handleChange} name="q11" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q11==="わからない"} onChange={handleChange} name="q11" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage3){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
             setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 4 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
         <div style={{width: "50%",fontSize: 18, backgroundColor: "#1c4fe5", padding: 2, borderRadius: 6, color: "#fff", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
         <div style={{width: "50%",fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid orange", color: "orange", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(1)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1){
                    setDisplayErrorMessage(false)     
                    setPageFlag(2)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1 && hasDoneInPage2){
                    setDisplayErrorMessage(false)     
                    setPageFlag(3)
                }else {
                 setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color:  "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage1 && hasDoneInPage2 && hasDoneInPage3){
                    setDisplayErrorMessage(false)     
                    setPageFlag(4)
                }else {
                 setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color: "#1c4fe5", transform: "scale(1.15)"}}>❹</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q12: ベランダ・バルコニーの床にひび割れはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q12==="なし"} onChange={handleChange} name="q12" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q12==="あり"} onChange={handleChange} name="q12" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q12==="わからない"} onChange={handleChange} name="q12" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q13: ベランダ・バルコニーの床を歩くときしみはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q13==="なし"} onChange={handleChange} name="q13" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q13==="あり"} onChange={handleChange} name="q13" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q13==="わからない"} onChange={handleChange} name="q13" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q14: ベランダ・バルコニーの手すりにグラつきはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q14==="なし"} onChange={handleChange} name="q14" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q14==="あり"} onChange={handleChange} name="q14" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q14==="わからない"} onChange={handleChange} name="q14" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q15: ベランダ・バルコニーの手すり周りのシーリング材（ゴム状の防水材）にひび割れや亀裂はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q15==="なし"} onChange={handleChange} name="q15" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q15==="あり"} onChange={handleChange} name="q15" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q15==="わからない"} onChange={handleChange} name="q15" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage4){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
             setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 5 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
           <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
           <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(5)}} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q16: 玄関の土間のタイルを叩くと軽い音がする箇所がありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q16==="なし"} onChange={handleChange} name="q16" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q16==="あり"} onChange={handleChange} name="q16" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q16==="わからない"} onChange={handleChange} name="q16" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
        </FormControl>
    
        </Box>

        {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
        
        <div style={{marginBottom: 20, marginTop: 50}}>  
        <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
            if(hasDoneInPage5){
                setDisplayErrorMessage(false)     
                setPageFlag(pageFlag+1)
            }else {
                setDisplayErrorMessage(true)  
            }
        }}
        >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
        </div>   
        </>}

    {pageFlag === 6 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
          <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{
               if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(5)
                }else {
                     setDisplayErrorMessage(true)  
                } 
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6){
                    setDisplayErrorMessage(false)     
                    setPageFlag(7)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q17: 床下収納庫の蓋を開けるとカビの臭いがしませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q17==="なし"} onChange={handleChange} name="q17" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q17==="あり"} onChange={handleChange} name="q17" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q17==="わからない"} onChange={handleChange} name="q17" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q18: 床下を覗いた事はありますか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q18==="なし"} onChange={handleChange} name="q18" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q18==="あり"} onChange={handleChange} name="q18" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q18==="わからない"} onChange={handleChange} name="q18" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q19: 床下は湿っていませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q19==="なし"} onChange={handleChange} name="q19" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q19==="あり"} onChange={handleChange} name="q19" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q19==="わからない"} onChange={handleChange} name="q19" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q20: 床下の断熱材が垂れ落ちているところはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q20==="なし"} onChange={handleChange} name="q20" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q20==="あり"} onChange={handleChange} name="q20" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q20==="わからない"} onChange={handleChange} name="q20" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q21: 床下の基礎にひび割れはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q21==="なし"} onChange={handleChange} name="q21" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q21==="あり"} onChange={handleChange} name="q21" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q21==="わからない"} onChange={handleChange} name="q21" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q22: 床下の基礎に筒状の土のかたまりがついていませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q22==="なし"} onChange={handleChange} name="q22" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q22==="あり"} onChange={handleChange} name="q22" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q22==="わからない"} onChange={handleChange} name="q22" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage6){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
            setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 7 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
          <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(5)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6){
                    setDisplayErrorMessage(false)     
                    setPageFlag(7)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7){
                    setDisplayErrorMessage(false)     
                    setPageFlag(8)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q23: 押入れ・クローゼットはカビの臭いはしませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q23==="なし"} onChange={handleChange} name="q23" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q23==="あり"} onChange={handleChange} name="q23" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q23==="わからない"} onChange={handleChange} name="q23" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q24: 天井裏を覗いた事はありますか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q24==="なし"} onChange={handleChange} name="q24" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q24==="あり"} onChange={handleChange} name="q24" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q24==="わからない"} onChange={handleChange} name="q24" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q25: 天井裏に断熱材が不足しているところはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q25==="なし"} onChange={handleChange} name="q25" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q25==="あり"} onChange={handleChange} name="q25" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q25==="わからない"} onChange={handleChange} name="q25" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q26: 天井裏に雨漏り跡はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q26==="なし"} onChange={handleChange} name="q26" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q26==="あり"} onChange={handleChange} name="q26" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q26==="わからない"} onChange={handleChange} name="q26" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage7){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
            setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 8 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
          <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(5)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6){
                    setDisplayErrorMessage(false)     
                    setPageFlag(7)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7){
                    setDisplayErrorMessage(false)     
                    setPageFlag(8)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7 && hasDoneInPage8){
                    setDisplayErrorMessage(false)     
                    setPageFlag(9)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q27: 室内を歩いた時に異音がしたり沈んだりしませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q27==="なし"} onChange={handleChange} name="q27" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q27==="あり"} onChange={handleChange} name="q27" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q27==="わからない"} onChange={handleChange} name="q27" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q28: 室内の壁、天井にひび割れはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q28==="なし"} onChange={handleChange} name="q28" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q28==="あり"} onChange={handleChange} name="q28" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q28==="わからない"} onChange={handleChange} name="q28" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q29: 室内の壁、天井のクロスに開きや剥がれはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q29==="なし"} onChange={handleChange} name="q29" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q29==="あり"} onChange={handleChange} name="q29" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q29==="わからない"} onChange={handleChange} name="q29" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q30: 室内の壁、天井に雨漏り・水濡れ跡はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q30==="なし"} onChange={handleChange} name="q30" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q30==="あり"} onChange={handleChange} name="q30" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q30==="わからない"} onChange={handleChange} name="q30" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q31: 室内の壁、天井にカビはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q31==="なし"} onChange={handleChange} name="q31" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q31==="あり"} onChange={handleChange} name="q31" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q31==="わからない"} onChange={handleChange} name="q31" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q32: 室内の床にビー玉が転がる程度の傾きはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q32==="なし"} onChange={handleChange} name="q32" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q32==="あり"} onChange={handleChange} name="q32" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q32==="わからない"} onChange={handleChange} name="q32" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q33: 和室の柱を叩くと軽い音がしませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q33==="なし"} onChange={handleChange} name="q33" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q33==="あり"} onChange={handleChange} name="q33" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q33==="わからない"} onChange={handleChange} name="q33" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage8){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
            setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 9 &&  
    <>
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
         <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
         <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(5)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6){
                    setDisplayErrorMessage(false)     
                    setPageFlag(7)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7){
                    setDisplayErrorMessage(false)     
                    setPageFlag(8)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7 && hasDoneInPage8){
                    setDisplayErrorMessage(false)     
                    setPageFlag(9)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7 && hasDoneInPage8 && hasDoneInPage9){
                    setDisplayErrorMessage(false)     
                    setPageFlag(10)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q34: 水道の水の量が少ない場所はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q34==="なし"} onChange={handleChange} name="q34" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q34==="あり"} onChange={handleChange} name="q34" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q34==="わからない"} onChange={handleChange} name="q34" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
        <p style={{textAlign: "left", fontWeight: "bold"}}>Q35: キッチン、洗面台の水を流す時にボコッとしませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q35==="なし"} onChange={handleChange} name="q35" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q35==="あり"} onChange={handleChange} name="q35" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q35==="わからない"} onChange={handleChange} name="q35" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q36: ガスコンロ（IHクッキングヒーター）に作動不良はありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q36==="なし"} onChange={handleChange} name="q36" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q36==="あり"} onChange={handleChange} name="q36" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q36==="わからない"} onChange={handleChange} name="q36" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>

      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage9){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
             setDisplayErrorMessage(true)  
        }
     }}
     >{pageFlag===4 ? "建物内部の診断へ" :"次へ"}</button>
    </div>   
    </>}

    {pageFlag === 10 && 
    <> 
      <Box>       
      <FormControl style={{ display: 'flex' }} >
      <div style={{display: "flex", justifyContent: "center", marginTop: 30}}>
          <div style={{width: "50%", fontSize: 18, backgroundColor: "#fff", padding: 2, borderRadius: 6, border: "2px solid #1c4fe5", color: "#1c4fe5", fontWeight: "bold"}}>建物の外部<div style={{fontSize: 6}}>（戸建住宅）</div></div>     
          <div style={{width: "50%", fontSize: 18, backgroundColor: "orange", padding: 2, borderRadius: 6, border: "2px solid orange", color: "#fff", fontWeight: "bold"}}>建物の内部<div style={{fontSize: 6}}>戸建住宅・マンションの住戸等</div></div> 
        </div>  
        <div style={{display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 5 }}>
            <div onClick={()=>{setPageFlag(5)}} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❶</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5){
                    setDisplayErrorMessage(false)     
                    setPageFlag(6)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❷</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6){
                    setDisplayErrorMessage(false)     
                    setPageFlag(7)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❸</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7){
                    setDisplayErrorMessage(false)     
                    setPageFlag(8)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❹</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7 && hasDoneInPage8){
                    setDisplayErrorMessage(false)     
                    setPageFlag(9)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "#cecece", transform: "scale(1.15)"}}>❺</div>
            <div style={{borderTop: "2px solid #cecece", content: "", width: "100%", alignSelf: "center"}}></div>
            <div onClick={()=>{
                if(hasDoneInPage5 && hasDoneInPage6 && hasDoneInPage7 && hasDoneInPage8 && hasDoneInPage9){
                    setDisplayErrorMessage(false)     
                    setPageFlag(10)
                }else {
                     setDisplayErrorMessage(true)  
                }
            }} style={{fontSize: 35, color: "orange", transform: "scale(1.15)"}}>❻</div>
        </div>
        <FormGroup>
            <p style={{textAlign: "left", fontWeight: "bold"}}>Q37: 建具に隙間やガタつきはありませんか。</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <FormControlLabel
                value="なし"
                control={ <Checkbox checked={q38==="なし"} onChange={handleChange} name="q38" /> }
                label="なし"
                labelPlacement="end"
            />
            <FormControlLabel
                value="あり"
                control={<Checkbox checked={q38==="あり"} onChange={handleChange} name="q38" />}
                label="あり"
                labelPlacement="end"
            />
            <FormControlLabel
                value="わからない"
                control={ <Checkbox checked={q38==="わからない"} onChange={handleChange} name="q38" /> }
                label="わからない"
                labelPlacement="end"
            />            
            </div>
        </FormGroup>
      </FormControl>
    </Box>

    {displayErrorMessage && <p style={{color: "red"}}>回答がない項目があります。</p>}
    
    <div style={{marginBottom: 20, marginTop: 50}}>  
    <button style={{width: "250px", height: 45, backgroundColor: pageFlag<5? "#1c4fe5": "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: pageFlag<5 ? "1px solid #1c4fe5" : "1px solid orange" , borderRadius: 1 }} onClick={()=>{
        if(hasDoneInPage10){
            setDisplayErrorMessage(false)     
            setPageFlag(pageFlag+1)
        }else {
            setDisplayErrorMessage(true)  
        }
     }}
     >結果を見る</button>
    </div>   
    </>}

    {pageFlag === 11 && 
    <> 
    <div style={{backgroundColor: "#EEEEEE", marginTop: 10, height: "100vh"}}>
      <div style={{textAlign: "left", padding: "15px 8%"}}>  
      <p style={{textAlign: "center", color: "#299fd6", fontWeight: "bold", fontSize: "28px"}}>セルフ診断結果</p>  
      <div style={{borderRadius: 10, backgroundColor: "#fff", padding: "20px 25px 100px 25px"}}>
        <p>{outputResultComment()}</p>
      </div>

      <div style={{textAlign: "center", marginTop: 50}}>
        <button　style={{width: "250px", height: 45, backgroundColor: "orange", color: "#fff", fontWeight: "bold", fontSize: 16, border: "1px solid orange" , borderRadius: 1 }} 
        onClick={()=>{navigate("/form")}}>検査依頼はこちら</button>
       </div>
    </div>
    </div>
    </>}
    </div>  
  )
}

export default Selfcheck;