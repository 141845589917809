import React, { useState, useEffect } from "react";
import { functions, db } from "./firebase"
import { httpsCallable } from "firebase/functions";
import { useLocation } from 'react-router-dom';
import { updateDoc, doc, getDoc } from "firebase/firestore"

import Button from "@material-ui/core/Button";
import { loadStripe } from "@stripe/stripe-js";

const StripePage = () => {
    const location = useLocation();
    const p = location.search.split("?p=")[1]
    const buildingId = location.search.split("?buildingId=")[1]
    const docRef = doc(db, "buildings", buildingId);
    const [data, setData] = useState({})

    useEffect(()=>{
        getTargetBuildingData()

        async function getTargetBuildingData(){
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setData({price: docSnap.data().price})
            }
        }
    }, [])


    console.log(data, "data")
    const showPayment = async () => {
    const createPaymentSession = httpsCallable(functions, 'createPaymentSession');


    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_KEY
    );

    const stripe = await stripePromise;     
    
    createPaymentSession({
          price: data?.price,

    }).then(async (result) => {
          stripe
            .redirectToCheckout({
              sessionId: result.data.id,
            })
            .then((result) => {
              console.log(result);
            });

            await updateDoc(docRef, {
                paidPrice: data?.price,
                paymentStatus: "完了",
                howToPay: "Stripe"
              });
        });


        
    };

    

    return(
     <div style={{margin: "30px 7%"}}>
        <p style={{fontWeight: "bold"}}>確認</p>
        <p>金額 {data?.price}円</p>
       <Button style={{ background: "#0E5C93",color: "#fff", marginTop: 45 }}
            onClick={() => showPayment()}>支払いページへ</Button>
     </div>   
    ) 
 }
 
 export default StripePage;