import React,{ useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, upload, storage } from "./firebase"
import liff from '@line/liff';
import { initLiff, getUserId } from "./liff"
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { prefecturesData, cityData } from "../src/utils/prefecturesData"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


console.log(String(prefecturesData), "prefecturesData")

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const InspectorForm = () => {
  const navigate = useNavigate()
  const theme = useTheme();
  const [skills, setSkills] = useState([""]);
  const [lineInfo, setLineInfo] = useState({});
  const [workArea, setWorkArea] = useState([{pref: "", cityName: []}])

  useEffect(()=>{
    initLiff()// 初期化処理
    console.log(liff.isInClient(), "liff.isInClient()")
    getUserInfo()
  }, [])

  const getUserInfo = () => {
    liff.getProfile().then(profile => {
        setLineInfo(profile)
    }).catch((error)=>{})
}





  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    console.log(data, "data")
    try {
      await submitInspectorForm(data)
      reset()
      //alert("送信しました。")
      navigate("/thank-you")
    } catch(err){
      console.log(err)
      alert("送信に失敗しました。")
    }
  }

  const submitInspectorForm = async (data) => {
    let allArea = []
    workArea.forEach((area)=>{
      allArea.push(...area["cityName"])
    })
    await upload(data.profilePhoto[0], `workers/${data.name}(${data.nameKana}).jpeg`)
    const storageRef = ref(storage, `workers/${data.name}(${data.nameKana}).jpeg`);
    getDownloadURL(storageRef)
      .then(async (url) => {
        await addDoc(collection(db, "workers"), {
          name: data.name,
          nameKana: data.nameKana,
          mailAddress: data.mailAddress,
          phoneNumber: data.phoneNumber,
          skill: skills,
          workArea: workArea,
          area: allArea,
          shift: [],
          belongs: data.belongs,
          lineId: lineInfo?.userId,
          proflePhotoUrl: url,
          isAccountCreated: false,
        });
    })
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const pref = typeof value === 'string' ? value.split(',') : value
   workArea[event.target.name]=pref[0]
 

    const selectedPref = prefecturesData.filter(pref=>pref.prefName===value)
    const selectedCityData = cityData[selectedPref[0].id]
    workArea[event.target.name]={pref: pref, cityName: selectedCityData}
    setWorkArea([...workArea])
  };

  const handleChange2 = (event) => {
    console.log(event, "event")
    const {
      target: { value },
    } = event;
    const cityName = typeof value === 'string' ? value.split(',') : value
    workArea[event.target.name]={pref: workArea[event.target.name]["pref"], cityName: cityName}
    setWorkArea([...workArea])
  };

  const generateInput = (type) => {
    if(type==="skill"){
    setSkills([...skills, ""])
    }else if(type==="workArea"){
      setWorkArea([...workArea, {pref: "", cityName: []}])
    }
  }

  const deleteInput = (type) => {
    if(type==="skill"){
    skills.pop()
    setSkills([...skills])
    }else if(type==="workArea"){
      workArea.pop()
      setWorkArea([...workArea])
    }
  }

  const updateSkill = (e) => {
    const id = e.target.name
    skills[id] = e.target.value
    setSkills([...skills])
  }

   return(
    <>
    <div style={{backgroundColor: "#f1f2f3"}}>  
      <div style={{textAlign: "left", padding: "50px 5%"}}>
        <h3 style={{borderLeft: "medium solid #299fd6"}}>　登録申請</h3>  
        <p>検査員として希望される方は、はじめに登録申請を行ってください。</p>
      </div> 
    </div> 
    <div style={{textAlign: "left", padding: "50px 5%"}}>   
    <form onSubmit={handleSubmit(onSubmit)}>
    <h3 style={{borderLeft: "medium solid #299fd6"}}>　申込者情報</h3>  
      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>ご氏名</h3>
        <input placeholder="佐藤太郎" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("name" ,{ required: true })} />
        {errors.name && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3 ><span style={{color:"#E02F2F"}}>*</span>フリガナ</h3>
        <input placeholder="サトウタロウ" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("nameKana" ,{ required: true })} />
        {errors.nameKana && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>メールアドレス</h3>
        <input placeholder="sato@example.com" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("mailAddress" ,{ required: true })} />
        {errors.mailAddress && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>電話番号</h3>
        <input placeholder="XXX-XXXX-XXXX" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("phoneNumber" ,{ required: true })} />
        {errors.phoneNumber && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>顔写真</h3>
        <input type="file" accept="image/*" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("profilePhoto" ,{ required: true })} />
        {errors.profilePhoto && <span style={{color:"#E02F2F"}}> 入力が必要な項目です。</span>}
      </div>

      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>対応可能エリア</h3>

        {workArea.map((area, id)=>{
          return (
            <>
              <div style={{display: "flex", flexDirection: "column"}}>
          <FormControl sx={{ m: 1, width: "97%" }}>
            <InputLabel>都道府県</InputLabel>
            <Select
              name={id}
              value={area["pref"]}
              onChange={handleChange}
              input={<OutlinedInput label="都道府県" />}
              renderValue={(selected) =>  {
                console.log(selected, "selected")
                return(
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}}
              MenuProps={MenuProps}
            >
              {prefecturesData.map((prefecture) => (
                <MenuItem
                  key={prefecture.prefCode}
                  value={prefecture.prefName}
                  style={getStyles(prefecture.prefName, area["pref"], theme)}
                >
                  {prefecture.prefName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
                
          <FormControl sx={{ m: 1, width: "97%" }}>
            <InputLabel>市町村</InputLabel>
            <Select
              name={id}
              multiple
              value={area["cityName"]}
             onChange={handleChange2}
              input={<OutlinedInput label="市町村" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {area["cityName"].map((city,i) => (
                <MenuItem
                  key={city+i}
                  value={city}
                  style={getStyles(city, city, theme)}
                >
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
      
        </div>
        <div style={{marginTop: 50}}></div>
            </>
          )
        })}

        <div style={{textAlign: "center"}}>   
        {workArea.length!==1 &&
        <button onClick={(e)=>{
          e.preventDefault()
          deleteInput("workArea")
          }}>-</button>}
        <button onClick={(e)=>{
          e.preventDefault()
          generateInput("workArea")
          }}>+</button>
        </div>


      </div>

      <div style={{marginTop:40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>所属事務所</h3>
        <input placeholder="株式会社〇〇" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("belongs" ,{ required: true })} />
        {errors.belongs && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>

      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>保有資格</h3>


        {skills.map((skill, i)=>{
          return(
            <>
            <input onChange={updateSkill} style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} type="text" name={i} list="skill" placeholder="ー" autocomplete="off" />
            <datalist id="skill">
              <option value="一級建築士 ◯◯◯◯◯号"></option>
              <option value="二級建築士 ◯◯◯◯◯号"></option>
              <option value="木造建築士 ◯◯◯◯◯"></option>
              <option value="管理建築士 ◯◯◯◯◯"></option>
              <option value="木造耐震資格者 ◯◯◯◯◯号"></option>
              <option value="適合証明技術者 ◯◯◯◯◯"></option>
              <option value="赤外線建物診断技能士 ◯◯◯◯◯"></option>
              <option value="古民家鑑定士1級 ◯◯◯◯◯"></option>
              <option value="古民家鑑定士2級 ◯◯◯◯◯"></option>
              <option value="伝統耐震診断士 ◯◯◯◯◯"></option>
              <option value="古民家床下診断士 ◯◯◯◯◯"></option>
            </datalist>
            <div style={{marginTop: 20}}></div>
            </>
          )
        })}

        <div style={{textAlign: "center"}}>   
        {skills.length!==1 &&
        <button onClick={(e)=>{
          e.preventDefault()
          deleteInput("skill")
          }}>-</button>}
        <button onClick={(e)=>{
          e.preventDefault()
          generateInput("skill")
          }}>+</button>
        </div>     
      </div>
      

      <div style={{textAlign: "center"}}>
      <input type="submit" style={{marginTop:50, marginBottom:50,height: 50, width: 150, borderRadius: 10, backgroundColor: "#0e5c93", color: "white", borderColor: "#0e5c93", cursor: "pointer" }} />
      </div>
    </form>
    </div>
    </> 
   )
}

export default InspectorForm