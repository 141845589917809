import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import InspectorForm from './InspectorForm';
import ClientForm from './ClientForm';
import ThankYou from "./ThankYou"
import ShiftForm from './ShiftForm';
import ShiftFromSubmitted from "./ShiftFormSubmitted"
import Privacypolicy from "./Privacypolicy"
import Inspector from "./Inspector"
import Selfcheck from "./Selfcheck" 
import TransferInfo from "./TransferInfo";
import AboutCommercialTransaction from "./AboutCommercialTransaction"
import MyPage from './MyPage';
import MyReport from "./MyReport"
import BuildingDocuments from "./BuildingDocuments"
import Logo  from "./images/zenjushin-logo.png"
import './App.css';
import StripePage from "./StripePage";
import Success from "./Success";
import Cancel from "./Cancel";


function App() {

  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<InspectorForm />}/>
        <Route path="/thank-you" element={<ThankYou />}/>
        <Route path="/mypage" element={<MyPage />}/>
        <Route path="/shiftform" element={<ShiftForm />}/>
        <Route path="/shiftform-submitted" element={<ShiftFromSubmitted />}/>
        <Route path="/myreport" element={<MyReport />}/>
        <Route path="/:buildingId/documents" element={<BuildingDocuments />}/>
        <Route path="privacypolicy" element={<Privacypolicy />}/>
        <Route path="/transferinfo" element={<TransferInfo />}/>
        <Route path="/stripepage" element={<StripePage />}/>
        <Route path="/success" element={<Success />}/>
        <Route path="/cancel" element={<Cancel />}/>
        <Route path="/about_commercial_transaction" element={<AboutCommercialTransaction/>}/>

        <Route path="/form" element={<ClientForm />}/>
        <Route path="/inspector/:workerId" element={<Inspector />}/>
        <Route path="/selfcheck" element={<Selfcheck />}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
