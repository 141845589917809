
const TransferInfo = () => {
    return(
     <div style={{margin: "30px 7%"}}>
       <h2>銀行振込情報</h2> 
       <p>銀行名 |<span style={{marginLeft: 9}}>三井住友銀行</span></p>
       <p>支店名 |<span style={{marginLeft: 9}}>新小岩支店（店番号232）</span></p>
       <p>種類 |<span style={{marginLeft: 9}}>普通預金</span></p>
       <p>口座番号 |<span style={{marginLeft: 9}}>7716278</span></p>
       <p>名前 |<span style={{marginLeft: 9}}>一般社団法人全国住宅診断促進協会</span></p>
       <p>名前（カタカナ）|<span style={{marginLeft: 9}}></span>ｲﾂﾊﾟﾝｼﾔﾀﾞﾝﾎｳｼﾞﾝｾﾞﾝｺｸｼﾞﾕｳﾀｸｿｸｼﾝｷﾖｳｶｲ</p>
     </div>   
    ) 
 }
 
 export default TransferInfo;