
const AboutCommercialTransaction = () => {
    return(
        <div style={{textAlign: "left", padding: "5px 5%"}}>
            <h2 style={{textAlign: "center"}}>特定商取引</h2>

            <h3 style={{borderLeft: "medium solid #299fd6"}}>　事業者名称</h3> 
           <p>一般社団法人 全国住宅診断促進協会</p>

            <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　サービス名称</h3> 
           <p>ながすむ</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　運営責任者</h3> 
           <p>島田昌明</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　問い合わせ窓口（メールアドレス）</h3> 
           <p>greenupform@gmail.com</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　問い合わせ窓口（電話番号）</h3> 
           <p>0120-540-569</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　本社所在地</h3> 
           <p>千葉県市川市真間５丁目１４番７号</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　サービス代金以外の必要料金</h3> 
           <p>銀行振込手数料</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　お支払い方法</h3> 
           <p>銀行振込<br/>クレジットカード決済<br/>PayPay</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　代金の支払い時期</h3> 
           <p>＜都度課金＞<br/>銀行振込：ご請求日より1週間以内<br/>クレジットカード決済：ご請求日より1週間以内<br/>PayPay：ご請求日より1週間以内</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　サービス開始時期</h3> 
           <p>サービス申し込みを行い検査日程確定後、サービスの提供を行います。</p>
           <div style={{marginTop :30}}></div>
           <h3 style={{borderLeft: "medium solid #299fd6"}}>　解約について</h3> 
           <p>・サービス特性上、返金・キャンセルについてはお受けできません。<br/>・主催者都合やなんらかの事情でサービスが中止になった場合は全額返金いたします。</p>
        </div>   
    )
}

export default AboutCommercialTransaction