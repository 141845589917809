import { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form";
import { db, upload, storage } from "./firebase"
import { collection, setDoc, updateDoc,doc, getDoc, query, getDocs, where } from "firebase/firestore"
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import liff from '@line/liff';

import './lib/css/mobiscroll.react.min.css';
import { Datepicker } from '@mobiscroll/react';

const ClientForm = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [zumenFiles, setZumenFiles] = useState([""])
  const [otherDocuments, setOtherDocuments] = useState([""])
  const [address, setAddress] = useState({})
  const [inspectionDateTime, setInspectionDateTime] = useState({})
  const postCode = useRef(null)
  const buildingPostCode = useRef(null)
  const address1 = useRef(null)
  const address2 = useRef(null)
  const address3 = useRef(null)
  const address4 = useRef(null)
  const address5 = useRef(null)
  const address6 = useRef(null)
  const navigate = useNavigate()
  const [lineInfo, setLineInfo] = useState({});
  const [loadingFlag, setLoadingFlag] = useState(false)
  const [type, setType] = useState("一戸建て")

  const handleType = (e) => {
    setType(e.target.value)
  }

  useEffect(() => {
    getUserInfo()
  }, []);

  const getUserInfo = () => {
  liff.getProfile().then(profile => {
      setLineInfo(profile)
  }).catch((error)=>{})
  }



  const onSubmit = async (data) => {
      console.log("CALLED", data)
    setLoadingFlag(true)
    let clientId = ""
    const buildingId = uuidv4()
    try{
      const q = query(collection(db, "clients"), where("mailAddress", "==", data.mailAddress)); // メールアドレスが同じ場合は顧客情報を作成
      const querySnapshot = await getDocs(q);
      if(!querySnapshot.empty){
        clientId = querySnapshot.docs[0].id
      }else {
        clientId =  uuidv4()
      }

      console.log(clientId,"clientId")
      let price = 0;
      if(type && data.floorArea){
         price = calculatePrice(data.buildingType,data.floorArea)
      }

      const clientData = {
        name: data.name,
        nameKana: data.nameKana,
        mailAddress: data.mailAddress,
        phone: data.phoneNumber,
        postCode: address?.postCode,
        address: address?.address1+address?.address2+address?.address3,
        lineId: lineInfo?.userId
      }

      const buildingData = {
        type: data.type,
        buildingType: type,
        inspectionDateTime: inspectionDateTime,
        postCode: address?.buildingPostCode,
        address: address?.address4+address?.address5+address?.address6,
        addressForSearch: address?.address5,
        ageOfBuilding: data.buildingAge,
        howToReceive: data.howToReceive,
        clientName: data.name,
        clientId: clientId,
        isFinished: false,
        asignStatus: "該当なし",
        isAssigned: false,
        isReportSent: false,
        price: price,
        buildingFloor: data.buildingFloor ? data.buildingFloor: "",
        buildingFloorUnder: data.buildingFloorUnder ? data.buildingFloorUnder: "",
        structure: data.structure ? data.structure: "",
        roomNumber: data.roomNumber ? data.roomNumber: "",
        floorArea: data.floorArea ? data.floorArea: "",
        agency: data.agency ? data.agency: "",
        question: data.question ? data.question: "",
      }

      console.log(data);
      console.log(clientData);
      console.log(buildingData);

      
      const clientDoc = doc(db,"clients",clientId);
      await setDoc(clientDoc, clientData)
      const buildingDoc = doc(db,"buildings",buildingId);
      await setDoc(buildingDoc, buildingData)

      const colRef = doc(collection(buildingDoc, "details"),"inspection1")
      await setDoc(colRef, {
      zenkeiInspected: false,
      yaneInspected: false,
      gaishubuInspected: false,
      rinchikyoukaiInspected: false,
      kisoInspected: false,
      gaihekiInspected: false,
      heikoubuInspected: false,
      nokimawariInspected: false,
      shitsunaiInspected: false,
      });

      const colRef2 = doc(collection(buildingDoc, "details"),"inspection2")
      await setDoc(colRef2, {
        gaibukisoReported: false,
        gaihekiReported: false,
        nokimawariReported: false,
        yaneReported: false,
        balconyReported: false,
        shikichigaishuuReported: false,
        shikichiReported: false,
        kousakubutsuReported: false,
        kyuuyukisetsubiReported: false,
        yukaReported: false,
        kabeReported: false,
        tenjouReported: false,
        genkanReported: false,
        shitsunaitateguReported: false,
        gaihekigawassashiReported: false,
        setsubibutsuReported: false,
        koyauraReported: false,
        tenjouurakoyauraReported: false,
        yukashitakisoReported: false,
        yukashitahaikanReported: false,
        yukagumidomaReported: false,
        kyuusuiReported: false,
        kyuuyusetsubiReported: false,
        haisuiReported: false,
        kankisetsubiReported: false,
        });

      const colRef3 = doc(collection(buildingDoc, "details"),"inspection3")
      await setDoc(colRef3, {
        kabeLevelReported: false,
        yukaLevelReported: false
        });

      await uploadDocuments(buildingId)  
      //alert("送信しました。")
      setLoadingFlag(false)
      reset()
      navigate("/thank-you")
      
    } catch(error){
      console.log(error)
      alert("送信に失敗しました。もう一度お試しください。")
    }
  }

  const calculatePrice = (buildingType, floorArea) => {
    if(buildingType==="一戸建て"){
      if(floorArea<125){
        return 99000
      }else{
        return Math.trunc(99000 + (floorArea-125) * 770) // 小数点切り捨て
      }
    }else { // 共同住宅(1部屋), 共同住宅(全棟)
      if(floorArea<100){
        return 55000
      }else {
        return Math.trunc(55000+(floorArea-100)*440) // 小数点切り捨て
      }
    }
  }

  const uploadDocuments = async (buildingId) => {
    for(let i=0; i<zumenFiles.length;i++){
      if(zumenFiles[i]){
      await upload(zumenFiles[i], `${buildingId}/documents/${zumenFiles[i].name}`)
      }
    }

    for(let i=0; i<otherDocuments.length;i++){
      if(otherDocuments[i]){
        await upload(otherDocuments[i], `${buildingId}/documents/${otherDocuments[i].name}`)
      }
    }
  }

  const currentYear = new Date().getFullYear();

  const optionData = ["不明"];

  for(let i=0; i<100; i++){
    var dt = new Date(currentYear-i, 1, 1, 1, 1, 0, 0);
    optionData.push((currentYear-i)+"年築"+` (${dt.toLocaleDateString('ja-JP-u-ca-japanese', {era:'long'}).split("/")[0]}年) `)  
  }

  const handleChange = e => {
    console.log(e.target.value, "e handleChange")
    address[e.target.id]=e.target.value
    console.log(address,"address")
  };


  const complementAddress = () => {
    const { AjaxZip3 } = window;
    AjaxZip3.zip2addr(
      'postCodeH',
      'postCodeF',
      'address1',
      'address2',
      'address3'
    );

  };

  const complementAddress2 = () => {
    const { AjaxZip3 } = window;

    AjaxZip3.zip2addr(
        'postCodeH2',
        'postCodeF2',
        'address4',
        'address5',
        'address6'
      );
  };

  const onBlurZipcode = () => {
    //setState({});
    console.log("CALLED")
    setAddress({postCode: postCode.current.value, address1: address1.current.value,address2: address2.current.value,address3: address3.current.value, buildingPostCode: buildingPostCode.current.value  ,address4: address4.current.value,address5: address5.current.value,address6: address6.current.value})
  };

  const handleFile = (e) => {
    if(e.target.name==="zumen"){
      zumenFiles[e.target.id] = e.target.files[0]
      setZumenFiles(zumenFiles)
    }else if(e.target.name==="otherDocuments"){
      otherDocuments[e.target.id] = e.target.files[0]
      setOtherDocuments(otherDocuments)
    }
  }


  const generateInput = (fileName) => {
    if(fileName==="zumen"){
      setZumenFiles([...zumenFiles, ""])
    } else if(fileName==="otherDocuments"){
      setOtherDocuments([...otherDocuments, ""])
    }
  }

  const deleteInput = (fileName) => {
    if(fileName==="zumen"){
      zumenFiles.pop()
      setZumenFiles([...zumenFiles])
    } else if(fileName==="otherDocuments"){
      otherDocuments.pop()
      setOtherDocuments([...otherDocuments])
    }
  }

  console.log(address, "address")

  console.log(inspectionDateTime, "inspectionDateTime")

  console.log(zumenFiles, "zumenFiles")
  console.log(otherDocuments, "otherDocuments")



  if(!loadingFlag){
   return(
    //<>
    //{Object.keys(lineInfo).length ? 
    <>  
    <div style={{backgroundColor: "#f1f2f3"}}>  
      <div style={{textAlign: "left", padding: "50px 5%"}}>
        <h3 style={{borderLeft: "medium solid #299fd6"}}>　建物状況調査をご希望の方へ</h3>  
        <p>全住診（一般社団法人全国住宅診断促進協会）は、これまで多くの戸建て・共同住宅の建物状況調査を行ってきました。</p>
        <div style={{marginTop: 30}}></div>
        <h3 style={{borderLeft: "medium solid #299fd6"}}>　登録申請</h3>  
        <p>建物状況調査を希望される方は、はじめに登録申請を行ってください。</p>
        <p>必要事項を記入頂き、「個人情報の取り扱い」の内容を確認の上、同意して申し込みください。</p>
      </div> 
    </div>   
    <div style={{display: "flex", justifyContent: "space-evenly", marginTop: 30}}>
        <button onClick={()=>{setType("一戸建て")}} style={{background: type==="一戸建て" ? "#299fd6": "#fff" ,color: type==="一戸建て" ? "#fff" : "#299fd6",width: 150,height: 50,borderColor:  type==="一戸建て" ? "#299fd6": "#fff", cursor: "pointer", borderRadius: 50, border: type==="一戸建て" ? "none": "solid 1px #299fd6"}}><span  style={{fontSize: 16}}>一戸建て</span><br/><span style={{fontSize: 6}}>の方はこちら</span></button>
        <button onClick={()=>{setType("共同住宅(1部屋)")}} style={{background: type==="一戸建て" ?  "#fff" : "#299fd6" ,color: type==="一戸建て" ? "#299fd6": "#fff" ,width: 150,height: 50,borderColor:  type==="一戸建て" ? "#fff":"#299fd6", cursor: "pointer", borderRadius: 50, border: type==="一戸建て" ? "solid 1px #299fd6": "none"}}><span  style={{fontSize: 16}}>共同住宅</span><br/><span style={{fontSize: 6}}>の方はこちら</span></button>
    </div>
    <div style={{textAlign: "left", padding: "50px 5%"}}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <h3 style={{borderLeft: "medium solid #299fd6"}}>　申込者情報</h3>  
      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>ご氏名</h3>
        <input placeholder="佐藤太郎" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("name" ,{ required: true })} />
        {errors.name && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3 ><span style={{color:"#E02F2F"}}>*</span>フリガナ</h3>
        <input placeholder="サトウタロウ" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("nameKana" ,{ required: true })} />
        {errors.nameKana && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>メールアドレス</h3>
        <input placeholder="sato@example.com" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("mailAddress" ,{ required: true })} />
        {errors.mailAddress && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>郵便番号</h3>
        <input
            ref={postCode}
            id="postCode"
            name="postCodeH"
            placeholder="XXXXXXX"  style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}
            size="7"
            maxLength="7"
            onChange={e => handleChange(e)}
            onKeyUp={complementAddress}
            onBlur={onBlurZipcode}
        />
      </div>

      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>ご住所</h3>

        <input
            ref={address1}
            name="address1"
            id="address1"
            placeholder="都道府県"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"100px",paddingLeft:5, fontSize: 16 }}
        />
        <input
            ref={address2}
            name="address2"
            id="address2"
            placeholder="市町村・区"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"180px",paddingLeft:5, fontSize: 16 }}
        />
        <input
            ref={address3}
            name="address3"
            id="address3"
            placeholder="町名・番地・建物名"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}
        />
      </div>
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>電話番号</h3>
        <input placeholder="XXX-XXXX-XXXX" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} {...register("phoneNumber" ,{ required: true })} />
        {errors.phoneNumber && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>

      <h3 style={{borderLeft: "medium solid #299fd6", marginTop: 90}}>　建物情報</h3>  

      <div style={{marginTop: 50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>建物種別</h3>
        <select {...register("type")} style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}>
            <option value="新築（建売）">新築（建売）</option>
            <option value="新築（注文）">新築（注文）</option>
            <option value="中古">中古</option>
        </select>
      </div>

      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>建物種類</h3>
        <select {...register("buildingType")} onChange={handleType}  style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}>
            {type === "一戸建て"  && <option value="一戸建て">一戸建て</option>}
            {type !== "一戸建て"  && <option value="共同住宅(1部屋)">共同住宅(1部屋)</option>}
            {type !== "一戸建て"  && <option value="共同住宅(全棟)">共同住宅(全棟)</option>}
        </select>
        
      </div>

      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>対象物件の郵便番号</h3>
        <input
            ref={buildingPostCode}
            id="buildingPostCode"
            name="postCodeH2"
            placeholder="XXXXXXX" style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}
            size="7"
            maxLength="7"
            onChange={e => handleChange(e)}
            onKeyUp={complementAddress2}
            onBlur={onBlurZipcode}
        />
      </div>
      
      <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>対象物件のご住所</h3>
        <input
            ref={address4}
            name="address4"
            id="address4"
            placeholder="都道府県"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"100px",paddingLeft:5, fontSize: 16 }}
       
        />
        <input
            ref={address5}
            name="address5"
            id="address5"
            placeholder="市町村・区"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"180px",paddingLeft:5, fontSize: 16 }}
     
        />
        <input
            ref={address6}
            name="address6"
            id="address6"
            placeholder="町名・番地・建物名"
            onChange={e => handleChange(e)}
            style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}
        
        />
      </div>
      <div style={{ marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>対象物件の築年数</h3>
        <select {...register("buildingAge")} style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}>
            {optionData.map((year)=>{
              return (
                <option key={year} value={`${year}`}>{year}</option>
              )  
            })}
        </select>
      </div>
    

      {type === "一戸建て" &&
    <>
      <div style={{marginTop: 40}}>
        <h3>一戸立ての階数</h3>
        <div>
        <span style={{marginRight:4}}>地上</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloor")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
        {errors.buildingFloor && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}

        <div style={{marginTop: 9}}>
        <span style={{marginRight:4}}>地下</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloorUnder")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
      </div>
      <div style={{marginTop: 40}}>
        <h3>建物の延べ床面積</h3>
        <div>
        <input type="number" step="0.01" placeholder="108.33" style={{"height":40, width: 60,paddingLeft:5, fontSize: 16 }} {...register("floorArea")} />
        <span style={{marginLeft:3}}>㎡</span>
        </div>
        <p style={{fontSize: "7px", marginTop: 10}}>125㎡を超える場合1㎡に付き540円(税込)の追加料金があります。</p>
        {errors.floorArea && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{ marginTop: 40}}>
        <h3>一戸建ての構造</h3>
        <select {...register("structure")} style={{"height":40, width:"97%",paddingLeft:5 }}>
            <option value={"木造(在来軸組工法)"}>木造(在来軸組工法)</option>
            <option value={"２×４"}>２×４</option>
            <option value={"鉄骨造（S造）"}>鉄骨造（S造)</option>
            <option value={"鉄筋造（RC造）"}>鉄筋造（RC造）</option>
            <option value={"古民家木造(伝統構法）"}>古民家木造(伝統構法）</option>
            <option value={"不明"}>不明</option>
        </select>
      </div>
      <div style={{marginTop: 40}}>
        <h3>売主・仲介会社</h3>
        <input placeholder="※不明な場合は空欄可" style={{"height":40, width:"95%",paddingLeft:5, fontSize: 16 }} {...register("agency")} />
      </div>
      </>}

      {type === "共同住宅(1部屋)" &&
        <>
        <div style={{marginTop: 40}}>
        <h3>部屋番号</h3>
        <input  type="number"　placeholder="202" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("roomNumber")} />
        <span style={{marginLeft:3}}>号室</span>
      </div>
      <div style={{marginTop: 40}}>
        <h3>共同住宅の階数</h3>
        <div>
        <span style={{marginRight:4}}>地上</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloor")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
        {errors.buildingFloor && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}

        <div style={{marginTop: 9}}>
        <span style={{marginRight:4}}>地下</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloorUnder")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
      </div>
      <div style={{marginTop: 40}}>
        <h3>専有面積</h3>
        <input　type="number" step="0.01" placeholder="108.33" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }}  {...register("floorArea")} />
        <span style={{marginLeft:3}}>㎡</span>
        <p style={{fontSize: "9px", marginTop: 10}}>100㎡を超える場合1㎡に付き525円(税込)の追加料金があります。</p>
      </div>
      <div style={{marginTop: 40}}>
        <h3>売主・仲介会社</h3>
        <input placeholder="※不明な場合は空欄可" style={{"height":40, width:"95%",paddingLeft:5, fontSize: 16 }} {...register("agency")} />
      </div>
        </>
      }

    {type === "共同住宅(全棟)" && 
    <>
      <div style={{marginTop: 40}}>
        <h3>部屋数</h3>
        <input  type="number" placeholder="3" style={{"height":40, width:"95%",paddingLeft:5, fontSize: 16 }} {...register("roomNumber",{ required: true })} />
        {errors.roomNumber && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{marginTop: 40}}>
        <h3>共同住宅の階数</h3>
        <div>
        <span style={{marginRight:4}}>地上</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloor")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
        {errors.buildingFloor && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}

        <div style={{marginTop: 9}}>
        <span style={{marginRight:4}}>地下</span>  
        <input type="number" placeholder="X" style={{"height":40, width:60,paddingLeft:5, fontSize: 16 }} {...register("buildingFloorUnder")} />
        <span  style={{marginLeft:3}}>階</span>
        </div>
      </div>
      <div style={{display:"flex", alignItems: "center", flexWrap: "wrap", marginTop: 20}}>
        <h3>延床面積</h3>
        <input  type="number" step="0.01" placeholder="108.33"  style={{"height":40, width:"95%",paddingLeft:5, fontSize: 16 }} {...register("floorArea",{ required: true })} />
        <span  style={{marginLeft:3}}>㎡</span>
        <p style={{fontSize: "7px", marginTop: 10}}>100㎡を超える場合1㎡に付き525円(税込)の追加料金があります。</p>
        {errors.occupiedArea && <span style={{color:"#E02F2F"}}>入力が必要な項目です。</span>}
      </div>
      <div style={{ marginTop: 40}}>
        <h3>売主・仲介会社</h3>
        <input placeholder="※不明な場合は空欄可" style={{"height":40, width:"95%", paddingLeft:5, fontSize: 16 }} {...register("agency")} />
      </div>
    </>
  }
  
    <div style={{marginTop: 40}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>報告書の受け取り方法</h3>
        <select {...register("howToReceive",{ required: true })} style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }}>
            <option value={"PDF（こちらは無料）"}>PDF（こちらは無料）</option>
            <option value={"郵送（宅配便）※有料3,240円"}>郵送（宅配便）※有料3,240円</option>
        </select>
      </div>

      <div style={{marginTop: 40}}>
      <h3 style={{borderLeft: "medium solid #299fd6"}}>　対象物件の書類</h3>  
        <p><span style={{color:"#E02F2F"}}>*</span>図面</p>
           {zumenFiles.map((zumenFile, i)=>{
            return(
              <div key={`zumenFile${i}`} style={{marginTop: 20}}>
                <input id={i} onChange={handleFile} name="zumen" type="file" accept="image/*,.pdf" required={i===0}/>
              </div>
            )
           })}
           <div style={{textAlign: "center"}}>   
            {zumenFiles.length!==1 &&
            <button onClick={(e)=>{
              e.preventDefault()
              deleteInput("zumen")
              }}>-</button>}
            <button onClick={(e)=>{
              e.preventDefault()
              generateInput("zumen")
              }}>+</button>
            </div> 

        {errors.zumen && <p><span style={{color:"#E02F2F"}}>図面を添付してください。</span></p>}
        <div style={{marginTop: 40}}></div>
      
       
        <div style={{marginBottom: 30}}></div> 
        <p>その他資料<br/>（立面図、仕様書、確認済書（確認申請書）、検査済書、建築台帳記載事項証明書、耐震基準適合証明書、長期優良住宅認定通知書など）</p>
        <p>⚠︎：適切なファイル名にしてください。<br/>（例：仕様書.pdf）</p>
           {otherDocuments.map((otherDocument, i)=>{
            return(
              <div key={`otherDocument${i}`} style={{marginTop: 20}}>
               <input  id={i} onChange={handleFile} name="otherDocuments" type="file" accept="image/*,.pdf,.xlsx" />
              </div>
            )
           })}
           <div style={{textAlign: "center"}}>   
           {otherDocuments.length!==1 &&
            <button onClick={(e)=>{
              e.preventDefault()
              deleteInput("otherDocuments")
              }}>-</button>}
            <button onClick={(e)=>{
              e.preventDefault()
              generateInput("otherDocuments")
              }}>+</button>
            </div> 
        <div style={{marginTop: 40}}></div>
    </div>  

      <div style={{marginTop: 90}}>
      <h3 style={{borderLeft: "medium solid #299fd6"}}>　検査希望日時</h3>  
        <p>検査時間は一戸建て約4時間、共同住宅（1部屋）で約2時間、共同住宅（全棟）で約5時間程度です。</p>
        <h3 style={{marginTop: 40}}><span style={{color:"#E02F2F"}}>*</span>第1希望日時</h3>
        <Datepicker
            label="日時 :"
            controls={['datetime']}
            touchUi={true}
            onChange={function (event, inst) {
               const date = {...inspectionDateTime,inspectionDateTime1: event.value }
               setInspectionDateTime(date)
            }}
            display="center"
            amText="午前"
            pmText="午後"
            setText="決定"
            cancelText="キャンセル"
            dayNamesShort={['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）']}
            monthNamesShort={["1 /","2 /","3 /","4 /","5 /","6 /","7 /","8 /","9 /","10 /","11 /","12 /"]}
            dateFormat='M月D日'
            timeWheels='A| h|mm'
            timeFormat='A h:mm'  
        />

        <h3 style={{marginTop: 40}}><span style={{color:"#E02F2F"}}>*</span>第2希望日時</h3>
        <Datepicker
            label="日時 :"
            controls={['datetime']}
            touchUi={true}
            display="center"
            onChange={function (event, inst) {
                const date = {...inspectionDateTime,inspectionDateTime2: event.value }
                setInspectionDateTime(date)
             }}
             amText="午前"
             pmText="午後"
             setText="決定"
             cancelText="キャンセル"
             dayNamesShort={['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）']}
             monthNamesShort={["1 /","2 /","3 /","4 /","5 /","6 /","7 /","8 /","9 /","10 /","11 /","12 /"]}
             dateFormat='M月D日'
             timeWheels='A| h|mm'
             timeFormat='A h:mm'  
        />

        <h3 style={{marginTop: 40}}><span style={{color:"#E02F2F"}}>*</span>第3希望日時</h3>
        <Datepicker
            label="日時 :"
            controls={['datetime']}
            touchUi={true}
            display="center"
            onChange={function (event, inst) {
                const date = {...inspectionDateTime,inspectionDateTime3: event.value }
                setInspectionDateTime(date)
             }}
             amText="午前"
             pmText="午後"
             setText="決定"
             cancelText="キャンセル"
             dayNamesShort={['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）']}
             monthNamesShort={["1 /","2 /","3 /","4 /","5 /","6 /","7 /","8 /","9 /","10 /","11 /","12 /"]}
             dateFormat='M月D日'
             timeWheels='A| h|mm'
             timeFormat='A h:mm'  
        />
    </div> 



      <h3 style={{borderLeft: "medium solid #299fd6", marginTop: 90}}>　お支払いについて</h3> 

      <p>報告書到着後1週間以内にお支払いお願い致します。支払い方法はクレジットカード、現金振込からお選び頂けます。</p>

      <div style={{marginTop: 40}}>
        <h3>連絡事項</h3>
        <textarea rows="6" style={{width:"97%",paddingLeft:5, resize: "none" , fontSize: 16}} {...register("question")} />
      </div>

      <p style={{fontWeight: "bold", textAlign: "center", marginTop: 70}}>★下記の内容をご確認・同意のうえお申し込みください。</p>
      <div style={{textAlign: "center",fontSize:"14px"}}>
        <div><a href="/privacypolicy/" target="_blank" rel="noopener noreferrer">個人情報の取り扱い</a></div>
      </div> 

      <div style={{display:"flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap"}}>
          <input {...register("privacy",{ required: true })} type="checkbox" value="個人情報の取り扱いに同意する"/>
          <label>個人情報の取り扱いに同意する</label>
      </div>
      {errors.privacy && <p style={{color:"#E02F2F", textAlign: "center"}}>個人情報の取り扱いを確認し、チェックを入れてください。</p>}

      <div style={{textAlign: "center"}}>
      <input type="submit" style={{marginTop:50,height: 50, width: 150, borderRadius: 10, backgroundColor: "#299fd6", color: "white", borderColor: "#299fd6", cursor: "pointer" }} />
      </div>

      </form>
    </div>
    </>
    // :
    //<p>PCではご利用できません。</p>}
    //        </> 
   )
    }else {
      return(
        <div style={{margin: "30px 7%"}}>
          <p>送信中...</p> 
        </div>  
      )
    }
}

export default ClientForm;