import liff from '@line/liff';

export const initLiff = () => {
    liff.init({ liffId: process.env.REACT_APP_LIFF_ID})
        .then(()=>{ 
            //ログインしていなければログインさせる
            //if(liff.isLoggedIn() === false) liff.login({})
        }).catch( (error)=> {

        });
}