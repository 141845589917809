
const Success = () => {

    return(
     <div style={{margin: "30px 7%"}}>
        <p>支払いが完了しました。<br/>ありがとうございました。</p>
     </div>   
    ) 
 }
 
 export default Success;