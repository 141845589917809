import React,{ useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import { collection, doc, setDoc, addDoc,updateDoc, getDocs, query, where } from "firebase/firestore";
import { db, upload, storage } from "./firebase"
import { ref, getDownloadURL } from "firebase/storage";
import liff from '@line/liff';
import { initLiff, getUserId } from "./liff"
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { prefecturesData, cityData } from "../src/utils/prefecturesData"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


console.log(String(prefecturesData), "prefecturesData")

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MyPage = () => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({});
    const [isEditing, setIsEditing] = useState(false)
    const theme = useTheme();
    const [profileImg, setProfileImg] = useState("")
    const [loadingFlag, setLoadingFlag] = useState(false)
    const [skills, setSkills] = useState([""]);
  const [workArea, setWorkArea] = useState([{pref: "", cityName: []}])

    useEffect(()=>{
        getUserInfo()
      }, [])
    
      const getUserInfo = async () => {
          console.log("call")
         await liff.getProfile().then(async (profile) => {
            const q = query(collection(db, "workers"), where("lineId", "==", profile.userId));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.empty){
              navigate("/")
              return;
            }
            await querySnapshot.forEach((doc) => {
                console.log(doc.id, "doc.ud")
                setUserData({id: doc.id, ...doc.data()})
                setProfileImg(doc.data().proflePhotoUrl)
                setWorkArea(doc.data().workArea)
                setSkills(doc.data().skill)
              });

      }).catch((error)=>{})
    }  

    const onFileInputChange = (e) => {
      if(e.target.name==="profileImg"){
        setProfileImg(e.target.files[0])
      }
   }

    console.log(userData)

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      const pref = typeof value === 'string' ? value.split(',') : value
     workArea[event.target.name]=pref[0]
   
  
      const selectedPref = prefecturesData.filter(pref=>pref.prefName===value)
      const selectedCityData = cityData[selectedPref[0].id]
      workArea[event.target.name]={pref: pref, cityName: selectedCityData}
      setWorkArea([...workArea])
    };
  
    const handleChange2 = (event) => {
      console.log(event, "event")
      const {
        target: { value },
      } = event;
      const cityName = typeof value === 'string' ? value.split(',') : value
      workArea[event.target.name]={pref: workArea[event.target.name]["pref"], cityName: cityName}
      setWorkArea([...workArea])
    };
  
    const generateInput = (type) => {
      if(type==="skill"){
      setSkills([...skills, ""])
      }else if(type==="workArea"){
        setWorkArea([...workArea, {pref: "", cityName: []}])
      }
    }
  
    const deleteInput = (type) => {
      if(type==="skill"){
      skills.pop()
      setSkills([...skills])
      }else if(type==="workArea"){
        workArea.pop()
        setWorkArea([...workArea])
      }
    }

    const updateSkill = (e) => {
      const id = e.target.name
      skills[id] = e.target.value
      setSkills([...skills])
    }

    const ButtonStyle = {
      background: "#0E5C93",
      color: "#fff",
      width: 100,
      height: 35,
      borderRadius: "8px",
      cursor: "pointer"
    }

  const { register, handleSubmit, reset } = useForm();
  const onSubmit = async (data) => {
    console.log(data, "data")
    try {
      setLoadingFlag(true)
      await updateInspectorInfo(data)
      setIsEditing(false)
      await delay(2)
      getUserInfo()
      setLoadingFlag(false)
    } catch(err){
      console.log(err)
      alert("更新に失敗しました。")
    }
  }

  const updateInspectorInfo = async (data) => {
    let allArea = []
    workArea.forEach((area)=>{
      allArea.push(...area["cityName"])
    })
    if(typeof profileImg==="object"){
      await upload(profileImg, `workers/${userData.name}(${userData.nameKana}).jpeg`)
      const storageRef = ref(storage, `workers/${userData.name}(${userData.nameKana}).jpeg`);
                getDownloadURL(storageRef)
                .then(async (url) => {
                  await updateDoc(doc(db, "workers", userData.id), {
                    proflePhotoUrl: url,
                    phoneNumber: data.phoneNumber,
                    skill: skills,
                    belongs: data.belongs,
                    area: allArea,
                    workArea: workArea,
                  });
                })
    }else {
      await updateDoc(doc(db, "workers", userData.id), {
        phoneNumber: data.phoneNumber,
        skill: skills,
        belongs: data.belongs,
        area: allArea,
        workArea: workArea,
      });
    }
  }

  function delay(n){
    return new Promise(function(resolve){
        setTimeout(resolve,n*1000);
    });
}

if(!loadingFlag){
  return (
    <div style={{textAlign: "left", padding: "50px 5%"}}>   
    {Object.keys(userData).length ?
    <>
    
      <h3 style={{borderLeft: "medium solid #299fd6", paddingLeft: "20px"}}>登録情報</h3>  
      {!isEditing ? 
      <>
      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>プロフィール画像</h3>
        <img src={profileImg} width="100%"></img>
      </div>
      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>ご氏名</h3>
        <p>{userData?.name} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>フリガナ</h3>
        <p>{userData?.nameKana} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>メールアドレス</h3>
        <p>{userData?.mailAddress} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>電話番号</h3>
        <p>{userData?.phoneNumber} </p>
      </div>

      <div style={{marginTop: 50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>対応可能エリア</h3>
        <div style={{display: "flex", flexWrap: "wrap"}}>
        {userData?.workArea?.length && userData?.workArea.map((area,i)=>{
            return(
              <div style={{marginRight: 5, marginBottom: 5}}>
              <p>{area.pref}</p>  
              {area.cityName.map((city, i)=>{
                return(
                  <Chip key={i} label={city} style={{marginRight: 5, marginBottom: 5}}/>
                )
              })}
              </div>
            )})}
        </div>
      </div>  

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>所属事務所</h3>
        <p>{userData?.belongs} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>保有資格</h3>
        {typeof userData?.skill === "string" ? 
        <p>{userData?.skill} </p>:
        <div style={{display: "flex", flexWrap: "wrap"}}>
          {userData?.skill.map((skill)=>{
          return (
            <div style={{marginRight: 5}}>{skill}</div>
          )
        })}
        </div>  
      }
      </div>

      <div style={{marginTop: 50, textAlign: "center"}}>
      <button onClick={()=>{setIsEditing(!isEditing)}} style={ButtonStyle}>編集</button>
      </div>   
      </>
      :
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
    <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>プロフィール画像</h3>
        <div style={{marginTop: 30}}>
          {profileImg && 
          <>
          <img src={typeof profileImg==="string" ? profileImg : window.URL.createObjectURL(profileImg)} width="100%"/>
          </>
          }
        <div style={{marginTop: 30,textAlign: "center"}}>
        <label style={{padding: "10px 20px", color: "#ffffff", backgroundColor: "#696969", cursor: "pointer", borderRadius: 10}}>
                                ファイルを選択
          <input type="file" name="profileImg" accept="image/*" onChange={onFileInputChange} style={{display: "none"}} />
        </label>
        </div>
      </div>
      </div>
 
    <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>ご氏名</h3>
        <p>{userData?.name} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>フリガナ</h3>
        <p>{userData?.nameKana} </p>
      </div>

      <div style={{marginTop:50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>メールアドレス</h3>
        <p>{userData?.mailAddress} </p>
      </div>

      <div style={{marginTop: 50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>電話番号</h3>
        <TextField id="outlined-basic" defaultValue={userData?.phoneNumber} variant="outlined" {...register("phoneNumber" ,{ required: true })} />
      </div>


      <div style={{marginTop: 50}}>
      <h3><span style={{color:"#E02F2F"}}>*</span>対応可能エリア</h3>
        
      {console.log(workArea, "workArea")}

{workArea.map((area, id)=>{
  return (
    <>
      <div style={{display: "flex", flexDirection: "column"}}>
      <FormControl sx={{ m: 1, width: "97%" }}>
        <InputLabel>都道府県</InputLabel>
        <Select
          name={id}
          value={area["pref"]}
          onChange={handleChange}
          input={<OutlinedInput label="都道府県" />}
          renderValue={(selected) =>  {
            console.log(selected, "selected")
            return(
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}}
          MenuProps={MenuProps}
        >
          {prefecturesData.map((prefecture) => (
            <MenuItem
              key={prefecture.prefCode}
              value={prefecture.prefName}
              style={getStyles(prefecture.prefName, area["pref"], theme)}
            >
              {prefecture.prefName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            
      <FormControl sx={{ m: 1, width: "97%" }}>
        <InputLabel>市町村</InputLabel>
        <Select
          name={id}
          multiple
          value={area["cityName"]}
        onChange={handleChange2}
          input={<OutlinedInput label="市町村" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {area["cityName"].map((city,i) => (
            <MenuItem
              key={city+i}
              value={city}
              style={getStyles(city, city, theme)}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
    <div style={{marginTop: 50}}></div>
    </>
  )
})}

<div style={{textAlign: "center"}}>   
{workArea.length!==1 &&
<button onClick={(e)=>{
  e.preventDefault()
  deleteInput("workArea")
  }}>-</button>}
<button onClick={(e)=>{
  e.preventDefault()
  generateInput("workArea")
  }}>+</button>
</div>


      </div>

      <div style={{marginTop: 50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>所属事務所</h3>
        <TextField fullWidth id="outlined-basic" defaultValue={userData?.belongs} variant="outlined"  {...register("belongs")} />
      </div>
      
      <div style={{marginTop: 50}}>
        <h3><span style={{color:"#E02F2F"}}>*</span>保有資格</h3>
        {skills && skills.map((skill, i)=>{
          return(
            <>
            <input onChange={updateSkill} defaultValue={skill} style={{"height":40, width:"97%",paddingLeft:5, fontSize: 16 }} type="text" name={i} list="skill" placeholder="ー" autocomplete="off" />
            <datalist id="skill">
              <option value="一級建築士 ◯◯◯◯◯号"></option>
              <option value="二級建築士 ◯◯◯◯◯号"></option>
              <option value="木造建築士 ◯◯◯◯◯"></option>
              <option value="管理建築士 ◯◯◯◯◯"></option>
              <option value="木造耐震資格者 ◯◯◯◯◯号"></option>
              <option value="適合証明技術者 ◯◯◯◯◯"></option>
              <option value="赤外線建物診断技能士 ◯◯◯◯◯"></option>
              <option value="古民家鑑定士1級 ◯◯◯◯◯"></option>
              <option value="古民家鑑定士2級 ◯◯◯◯◯"></option>
              <option value="伝統耐震診断士 ◯◯◯◯◯"></option>
              <option value="古民家床下診断士 ◯◯◯◯◯"></option>
            </datalist>
            <div style={{marginTop: 20}}></div>
            </>
          )
        })}

      <div style={{textAlign: "center"}}>   
        {skills.length!==1 &&
        <button onClick={(e)=>{
          e.preventDefault()
          deleteInput("skill")
          }}>-</button>}
        <button onClick={(e)=>{
          e.preventDefault()
          generateInput("skill")
          }}>+</button>
        </div>   

      </div>
      
      <div style={{marginTop: 50, marginBottom: 50, display: "flex", justifyContent: "space-evenly"}}>
         <button onClick={()=>{setIsEditing(!isEditing)}} style={ButtonStyle}>キャンセル</button>
        <input type="submit" value={"更新"} style={ButtonStyle} />
      </div>
      </form>

    </>}

      </>:<></>}
    </div>
  )
}else{
  return(
    <div style={{margin: "30px 7%"}}>
          <p>更新中...</p> 
    </div>  
  )
}}

export default MyPage