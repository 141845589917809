
const Privacypolicy = () => {
  return (
    <>
      <div style={{backgroundColor: "#f1f2f3"}}>  
      <div style={{textAlign: "left", padding: "50px 5%"}}>
      <div style={{backgroundColor: "#fff", padding: "20px 4%", borderRadius: 10}}>  
        <h2 style={{borderBottom: "medium solid #299fd6", textAlign: "center", paddingBottom: 10}}>　プライバシーポリシー</h2>  
        <p>全住診（一般社団法人全国住宅診断促進協会）（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。</p>
        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>個人情報の管理</p>  
        <p>当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</p>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>個人情報の利用目的</p>  
        <p>お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>個人情報の第三者への開示・提供の禁止</p>  
        <p>当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
        <ul>
          <li>お客さまの同意がある場合</li>  
          <li>お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合</li>
          <li>法令に基づき開示することが必要である場合</li>
        </ul>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>個人情報の安全対策</p>  
        <p>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>ご本人の照会</p>  
        <p>お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</p>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>法令、規範の遵守と見直し</p>  
        <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>

        <div style={{marginTop: 30}}></div>
        <p style={{fontWeight: "bold"}}>お問い合せ</p>  
        <p>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</p>
        <div style={{marginTop: 80}}></div>
        <p style={{textAlign: "center"}}>一般社団法人全国住宅診断促進協会<br/>〒272-0826　千葉県市川市真間5-14-7<br/>代表者：島田昌明<br/>FREE:0120-540-569<br/>TEL:043-373-0544 <br/>FAX:043-373-7900<br/>Mail:tafne2000@greenupform.com</p>
      </div> 
      </div>
     </div>     
    </>  
  )
}

export default Privacypolicy;