import React,{ useState, useEffect } from "react"
import { collection, doc, setDoc, addDoc,updateDoc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db, upload, storage } from "./firebase"
import { ref, getDownloadURL } from "firebase/storage";
import liff from '@line/liff';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import ReactLoading from 'react-loading';

const MyReport = () => {
    const [bildingData,setBuildingData] = useState([])
    const [loadingFlag, setLoadingFlag] = useState(false)

    useEffect(()=>{
        getUserInfo()
      }, [])
    
      const getUserInfo = async () => {
          setLoadingFlag(true)
          await liff.getProfile().then(async (profile) => {
          const q = query(collection(db, "clients"), where("lineId", "==", profile.userId)); //"U0e2e6ccc449c25422d4e1ba300e4eec3"

          let result = []
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
            const q2 = query(collection(db, "buildings"), where("clientId", "==", doc.id)); //profile.userId
                const querySnapshot2 = await getDocs(q2);
                querySnapshot2.forEach(async (doc) => {
                    result.push({id: doc.id, ...doc.data()})
                })
            });
            await delay(2)
            setBuildingData(result)
            setLoadingFlag(false)
  
     }).catch((error)=>{
      setLoadingFlag(false)
     })
    }  

    function delay(n){
        return new Promise(function(resolve){
            setTimeout(resolve,n*1000);
        });
      }
    
    console.log(bildingData, "bildingData")

    return(
      <>
      {loadingFlag  ? <ReactLoading type="bubbles" color="#A9A9A9" height={"5%"} width={"80%"} />: 
        <div style={{textAlign: "center", padding: "5px 5%"}}>
   
            <p style={{fontWeight: "bold", marginBottom: 30, marginTop: 30}}>依頼物件一覧</p>
      
            {bildingData.length> 0 ?
            bildingData.map((building)=>{
              return(
                <div>
                  <img width="70%" src={building?.buildingPicture.src}/> 
                  <p>{building.address}</p> 
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 25}}>
                    {building.hasOwnProperty("report") ? <a style={{marginRight: 15}} href={building.report.src} target="_blank" rel="noopener noreferrer">報告書</a> : <></>}
                    <Link style={{marginRight: 15}} to={`/${building.id}/documents`} >物件資料</Link>
                  </div>
                </div>
              )  
            }): <p>依頼した物件はありません。</p> }
        </div>   
        } 
      </>  
    )
}

export default MyReport