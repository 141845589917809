import React, { useState } from 'react';
import './lib/css/mobiscroll.react.min.css';
import { Eventcalendar, localeJa, getJson, confirm  } from '@mobiscroll/react';
import liff from '@line/liff';
import { initLiff, getUserId } from "./liff"
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, addDoc,updateDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase"
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


const ShiftForm = () => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({});
    const [myEvents, setEvents] = useState([]);

    const showConfirm = (targetId) => {
        confirm({
            title: '',
            message: '選択中のシフトを削除しますか？',
            okText: 'する',
            cancelText: 'しない',
            callback: (result) => {
              if(result){
                deleteTargetShift(targetId)
              }
            }
        });
    }

    const showConfirm2 = () => {
        confirm({
            title: "",
            message: 'シフトを提出しますか？',
            okText: '提出',
            cancelText: 'キャンセル',
            callback: (result) => {
              if(result){
                submitShiftForm()
              }
            }
        });
    }

    React.useEffect(() => {
        //initLiff()// 初期化処理

        getUserInfo()
    }, []);

    const getUserInfo = async () => {
        console.log("call")
       await liff.getProfile().then(async (profile) => {
          const q = query(collection(db, "workers"), where("lineId", "==",profile.userId)); //"Uc05621b555c209201e9ea2e350e30407" //profile.userId
          const querySnapshot = await getDocs(q);
          if(querySnapshot.empty){
            navigate("/")
            return;
          }

          await querySnapshot.forEach((doc) => {
            setUserData({id: doc.id, ...doc.data()})
            // 現在の時間と比較して昔のシフトを残さないように
            const now = Math.floor(Date.now() / 1000);
            const shiftDate = doc.data().shift.filter(s=>s.endDate.seconds>now)

            let lastShiftSeconds = 0
            let lastShiftIndex = 0
            shiftDate.forEach((d, i)=>{
              if(lastShiftSeconds<d.startDate.seconds){
                lastShiftSeconds = d.startDate.seconds
                lastShiftIndex=i
              }
            }) 
           
            const submitlastDay = shiftDate.length ? shiftDate[lastShiftIndex].start : moment()

            // シフト提出の最後日の後にデフォルトのシフトを配置
            for(let i=1;i<(31-shiftDate.length);i++){
              const id = uuidv4()
              let day = moment(submitlastDay).add(i, "d").format("YYYY-MM-DD");
              let start = `${day}T08:00:00+09:00`
              let end = `${day}T20:00:00+09:00`
              let startDate =  moment(start)._d
              let endDate =  moment(end)._d
              shiftDate.push({id: id,　allDay: "", end: end, endDate: endDate, start: start, startDate: startDate, title: "稼働可"})
            }
        
            setEvents(shiftDate)
            
          });  
      }).catch((error)=>{})
  }  

    //console.log(myEvents, "myEvents")

    //console.log(userData, "userData")

  const submitShiftForm = async () => {
    const workerDoc = doc(db, "workers", userData.id)
    console.log(myEvents, "myEvents")
    await updateDoc(workerDoc, {shift: myEvents})
    navigate("/shiftform-submitted")
  }  

  
  const deleteTargetShift = async (targetId) => {
    const filterdSchedlue = myEvents.filter((myEvent)=>myEvent.id!==targetId)
     setEvents([...filterdSchedlue])
  }  
  
    
    const responsive = React.useMemo(() => {
        return {
            xsmall: {
                view: {
                    schedule: { type: 'day', allDay: false, startTime: '06:00', endTime: "22:00" },
                    allDay: false
                }
            },
            custom: { // Custom breakpoint
                breakpoint: 600,
                view: {
                    schedule: { type: 'week', allDay: false, startTime: '06:00', endTime: "22:00" }
                    
                }
            }
        };
    }, []);

    return (
        <div style={{margin: "20px 1%"}}>
        {Object.keys(userData).length ?
        <>
        <div style={{textAlign: "right"}}>
        <button style={{height: "30px"}} onClick={()=>{
            showConfirm2()
            }}>提出</button>
        </div>   
        <Eventcalendar
            dayNamesMin={['日', '月', '火', '水', '木', '金', '土']}
            monthNames={['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']}
            newEventText="稼働可"
            theme="ios" 
            themeVariant="light"
            clickToCreate={true}
            onEventCreate={function (event, inst) {
                console.log(event, "e")
                const id = uuidv4()

                const start = moment(event.event.start).format()
                const end = moment(event.event.end).format()

                //.toISOString()
                console.log(start, "start")
                console.log(end, "start")
                console.log(event.event.start.toISOString())
                console.log(event.event.end.toISOString())
                
                setEvents([...myEvents, {id: id, start: start, startDate: event.event.start, end: end, endDate: event.event.end, title: event.event.title}])
            }}
            onEventDragEnd={function (event, inst) {
               console.log("DRAG END") 
               console.log(event)

               const targetSchedlue = myEvents.find((myEvent)=>myEvent.id===event.event.id)
               console.log(targetSchedlue, "targetSchedlue")
               
               if(targetSchedlue){
               const start = moment(targetSchedlue?.start).format()
                const end = moment(targetSchedlue?.end).format()

                targetSchedlue["startDate"] = targetSchedlue.start
                targetSchedlue["endDate"] = targetSchedlue.end
                targetSchedlue["start"] = start
                targetSchedlue["end"] = end
                targetSchedlue["allDay"] = ""
               }

               console.log(targetSchedlue, "targetSchedlue")
               setEvents([...myEvents])
            }}
            
            onEventClick={function (event, inst) {
                console.log("CLICKED") 
                
            }}
            
            onEventDoubleClick={function (event, inst) {
                showConfirm(event.event.id)
            }}
        

            dragToCreate={true}
            dragToMove={true}
            dragToResize={true}
            data={myEvents}
            responsive={responsive}
       />
       </>:
       <></>}
 
       <p style={{marginTop:20,marginLeft: 9,textAlign: "right", fontSize: 9}}>*シフトを2回タップすると削除ができます。</p>
       
       </div>
    ); 
}

export default ShiftForm;