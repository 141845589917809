import React,{ useState, useEffect } from "react"
import { useLocation } from 'react-router-dom';
import { db } from "./firebase"
import { doc, getDoc } from "firebase/firestore"

const Inspector = () => {
    const location = useLocation();
    const workerId = location.pathname.split("/")[2]
    const [workerInfo, setWorkerInfo] = useState("")
    console.log(workerId, "workerId")
    console.log(workerInfo, "workerInfo")

    useEffect(()=>{
        getWorkerInfo()
        async function getWorkerInfo(){
            const workerIdCollectionRef = doc(db, "workers", workerId)
            const data = await getDoc(workerIdCollectionRef)
            setWorkerInfo(data.data())
        }  
    }, [])

    return(
    <>
    {workerInfo && 
    <div>
      <div style={{textAlign: "left", padding: "10px 5%"}}>
        <h3 style={{borderLeft: "medium solid #299fd6"}}>　検査員情報</h3> 
        <img src={workerInfo?.proflePhotoUrl} width="100%"/> 
        <h3>名前</h3>
        <p>{workerInfo?.name}</p>
        <h3>保有資格</h3>
        {typeof workerInfo?.skill === "string" ? 
        <p>{workerInfo?.skill} </p>:
        <div style={{display: "flex", flexWrap: "wrap"}}>
          {workerInfo?.skill.map((skill, i)=>{
          return (
            <div key={i} style={{marginRight: 7}}>{skill}</div>
          )
        })}
        </div>  
      }
      </div> 
    </div>}
    </> 
   )
}

export default Inspector;